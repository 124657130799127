import * as React from 'react';

import Shell from '../components/Shell';

export default () => {
    return (
        <Shell>
            <div className="page about">
                <h1>About</h1>

                <p>
                    I am an artist based in Melbourne and enjoy combining my
                    passion for science and art to create vibrant works
                    exploring all things nature inspired. I work with a variety
                    of traditional mediums including acrylic, watercolour,
                    pencils and pens as well as digital mediums. I can do
                    digital pieces as well as pieces on canvas, paper, card and
                    wood to a variety of sizes.
                </p>
                <p>
                    I have studied immunology and Microbiology at Monash
                    University, completing a Bachelor of Science Advanced -
                    Research (Honours) and have used my interest in microbiology
                    to accurately illustrate microbes such as bacteria and
                    viruses, including characteristic morphology and physical
                    characteristics whilst maintaining a sense of whimsical
                    creative flair.
                </p>
                <p>
                    I have also used these unseen small things to inspire my
                    creative critter series which I heavily explore vibrant
                    colours and tantalizing textures in illustrating the unseen
                    world. I enjoy playing with the vibrancy of colour to create
                    works that challenge the genuine perception, where I invite
                    the viewer to linger their gaze on the often unnoticed and
                    invite them to explore a vibrant world of imagination.
                </p>
                <p>
                    <b>
                        Please note: In order to minimize face-face contact
                        during the current Covid-19 crisis, digital works are
                        preferred at this time.
                    </b>
                </p>
            </div>
        </Shell>
    );
};
