import Link from 'gatsby-link';
import * as React from 'react';

import fb from './images/fbLight.svg';
import ig from './images/igLight.svg';

import './Footer.scss';

export default () => {
    return (
        <div className="footer">
            <Link to="/contact" className="contact-link">
                <div className="contact">
                    <div>CONTACT</div>
                </div>
            </Link>
            <div className="split" />
            <div className="social">
                <div className="fb">
                    <a
                        href="https://www.instagram.com/kathleen.strumila/"
                        target="_blank">
                        <img src={fb} />
                    </a>
                </div>
                <div className="ig">
                    <a
                        href="https://www.instagram.com/kathleen.strumila/"
                        target="_blank">
                        <img src={ig} />
                    </a>
                </div>
            </div>
        </div>
    );
};
