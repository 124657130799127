import * as React from 'react';

import Footer from './Footer';
import Header from './Header';

import './Shell.scss';

export default ({ children }: { children: any }) => {
    return (
        <div className="shell">
            <Header />
            <div className="content">{children}</div>
            <Footer />
        </div>
    );
};
