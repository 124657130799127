import Link from 'gatsby-link';
import * as React from 'react';

import fb from './images/fb.svg';
import ig from './images/ig.svg';

import './Header.scss';

export default () => {
    return (
        <div className="header">
            <div className="title">
                <Link to="/">KATHLEEN STRUMILA</Link>
            </div>

            <div className="sep" />

            <div className="nav-right">
                <div className="nav-link">
                    <Link
                        to="/gallery"
                        activeClassName="active"
                        partiallyActive={true}>
                        GALLERY
                    </Link>
                </div>
                <div className="nav-link">
                    <Link to="/about" activeClassName="active">
                        ABOUT
                    </Link>
                </div>
                <div className="social-link fb">
                    <a
                        href="https://www.instagram.com/kathleen.strumila/"
                        target="_blank">
                        <img src={fb} />
                    </a>
                </div>
                <div className="social-link ig">
                    <a
                        href="https://www.instagram.com/kathleen.strumila/"
                        target="_blank">
                        <img src={ig} />
                    </a>
                </div>
                <Link to="/contact" className="button-container">
                    <div className="contact">CONTACT</div>
                </Link>
            </div>
        </div>
    );
};
